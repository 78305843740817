<script>
import TermosEPrivacidadePageBase from '@/components/TermosEPrivacidade/PageBase.vue';

export default {
  components: {
    TermosEPrivacidadePageBase,
  },
};
</script>
<template>
  <TermosEPrivacidadePageBase identificador-pagina="f6ed2ab8-266b-6cdd-07df-9c5025ca8ab1" />
</template>
