<script>
import TermosEPrivacidadePageBase from '@/components/TermosEPrivacidade/PageBase.vue';

export default {
  components: {
    TermosEPrivacidadePageBase,
  },
};
</script>
<template>
  <TermosEPrivacidadePageBase identificador-pagina="3287fe65-f98c-bc6f-9524-a5441d64c930" />
</template>
