import axios from 'axios';

const axiosHeadersBase = {
  versao: process.env.VUE_APP_VERSAO,
  token: process.env.VUE_APP_KEY_FRONT_API,
  'Content-Type': 'application/json;charset=utf-8',
};

export const HTTPClient = axios.create({
  baseURL: process.env.VUE_APP_URL_FRONTAPI,
  headers: axiosHeadersBase,
});
