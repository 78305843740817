<script>
export default {
  props: {
    variant: {
      type: String,
      default: 'primary',
      validator: (v) => ['primary', 'dark', 'secondary', 'danger'].includes(v),
    },
    size: {
      type: [String, Number],
      default: 25,
    },
    width: {
      type: [String, Number],
      default: 5,
    },
  },
};
</script>
<template>
  <div :class="`spinner--${variant}`">
    <svg :height="size" :width="size" viewBox="0 0 50 50">
      <g class="spinner__circular">
        <circle
          class="spinner__path"
          cx="25"
          cy="25"
          r="20"
          fill="none"
          :stroke-width="width"
          stroke-miterlimit="10"
          stroke="currentColor"
        />
      </g>
    </svg>
  </div>
</template>
<style lang="scss" scoped>
.spinner {
  &--dark {
    color: #111316;
  }

  &--primary {
    color: #01b1ec;
  }

  &--secondary {
    color: #ffffff;
  }

  &--danger {
    color: #ef4444;
  }

  &__circular {
    -webkit-animation: CircularRotate 2s linear infinite;
    animation: CircularRotate 2s linear infinite;
    height: 50px;
    -webkit-transform-origin: center center;
    -ms-transform-origin: center center;
    transform-origin: center center;
    width: 50px;
  }

  &__path {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    -webkit-animation: CircularDash 1.5s ease-in-out infinite;
    animation: CircularDash 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
  @-webkit-keyframes CircularRotate {
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
  @keyframes CircularRotate {
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
  @-webkit-keyframes CircularDash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes CircularDash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
  @keyframes CircularRotate {
    100% {
      -webkit-transform: rotate3d(0, 0, 1, 360deg);
      transform: rotate3d(0, 0, 1, 360deg);
    }
  }
  @keyframes CircularDash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
}
</style>
