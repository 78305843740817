<script>
import { PaginaInformativa } from '@/services/kotas';
import NavBar from '@/components/NavBar';
import Spinner from '@/components/Spinner';

export default {
  components: {
    Spinner,
    NavBar,
  },

  props: {
    identificadorPagina: String,
  },

  data: () => ({
    /**
     * Título da página antes de ser alterado
     */
    currentTitle: window.document.title,
    data: null,
    isError: false,
    isLoading: false,
  }),

  computed: {
    content() {
      return this.data?.conteudo || '';
    },
  },

  created() {
    window.document.title = this.$route.name;

    this.fetchPageContent(this.identificadorPagina);
  },

  beforeDestroy() {
    window.document.title = this.currentTitle;
  },

  methods: {
    async fetchPageContent(id) {
      try {
        this.isLoading = true;
        const { data } = await PaginaInformativa.obterPorIdentificador(id);
        this.data = data?.[0];
      } catch {
        this.isError = true;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<template>
  <div>
    <NavBar target="home" />

    <div class="page-base">
      <div v-if="isLoading" class="page-base__loading">
        <Spinner size="50" width="3" />
        <span> Carregando Conteúdo... </span>
      </div>

      <div v-else-if="isError" class="page-base__error">Ocorreu um erro ao carregar a página</div>

      <div class="page-base__content">
        <div v-html="content" class="ql-editor"></div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/fonts.scss';

.page-base {
  box-sizing: border-box;
  max-width: 70rem;
  width: 100%;
  margin: 0 auto;
  padding: 5rem 0 1rem 0;

  * {
    box-sizing: border-box;
  }

  &__content {
    & * {
      font-family: $mulish;
    }

    .ql-editor ::v-deep(ol),
    .ql-editor ::v-deep(ul) {
      padding-left: 0.75rem;
    }
  }

  &__error,
  &__loading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 10rem;
  }

  &__loading {
    & span {
      color: #616161;
      font-family: $mulish;
      font-size: 0.9rem;
      font-weight: 700;
    }
  }

  &__error {
    color: #616161;
    font-family: $mulish;
    font-size: 1rem;
    font-weight: 700;
  }
}
</style>

<style lang="scss">
@import '../../styles/quill.scss';
</style>
