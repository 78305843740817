import Home from '@/pages/Home.vue';

export default [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/servico/:path',
    name: 'Serviço Genérico',
    component: () => import('@/Generic/index.vue'),
  },
  {
    path: '/campanha',
    name: 'Campanha',
    component: () => import('@/pages/Campanha'),
  },
  {
    path: '/termos-de-uso',
    name: 'Termos de Uso',
    component: () => import('@/pages/TermosUso'),
  },
  {
    path: '/politica-de-privacidade',
    name: 'Política de Privacidade',
    component: () => import('@/pages/PoliticasDePrivacidade'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/pages/404.vue'),
  },
];
